import {InputEventUtils} from '../utils/input_event_utils';
import {OverlayScrollbar} from '../components/overlay_scrollbar';
import {AppUtils} from '../utils/app_utils';

export class SocialContentOverlay {
  container: HTMLElement;
  private overlayScrollbar: OverlayScrollbar;

  constructor(
    container: HTMLElement,
    private embedCode: string,
    private showLoader: boolean,
    private closeCallback: Function
  ) {
    this.container = container;
    this.container.innerHTML = '';
    const root = document.createElement('div');
    root.classList.add(
      'iframe-overlay',
      'overlay-container',
      'is-flex',
      'is-vertical',
      'is-vertically-aligned-center',
      'is-horizontally-aligned-center',
      'social-media-overlay',
      'x'
    );
    this.container.appendChild(root);

    const closeButton = document.createElement('div');
    closeButton.classList.add('overlay-button', 'overlay-button-close', 'will-close');
    closeButton.appendChild(document.createElement('span'));
    closeButton.addEventListener('click', this.closeOverlay.bind(this));
    root.appendChild(closeButton);

    const holder = document.createElement('div');
    holder.classList.add('iframe-holder');
    root.appendChild(holder);
    if (this.showLoader) {
      root.appendChild(AppUtils.showLoader());
    }

    holder.innerHTML = embedCode;
    this.executeScriptElements(holder);
    this.overlayScrollbar = new OverlayScrollbar('.iframe-holder', {sizeAutoCapable: false});

    if (closeButton) {
      InputEventUtils.addSelectEvents(closeButton, this.closeOverlay.bind(this));
    } else {
      console.error('element not found - ".overlay-button-close"');
    }
    const observer = new MutationObserver(records => {
      for (const record of records) {
        for (const node of record.addedNodes) {
          const iframe = AppUtils.isHtmlElement(node) && node.querySelector<HTMLElement>('iframe');

          if (iframe) {
            iframe.onload = () => {
              AppUtils.removeLoader();
            };
          } else {
            AppUtils.removeLoader();
          }
          observer.disconnect();
        }
      }
    });
    observer.observe(root, {childList: true, subtree: true});
  }

  private executeScriptElements(containerElement) {
    const scriptElements = containerElement.querySelectorAll('script');

    Array.from(scriptElements).forEach((scriptElement: HTMLScriptElement) => {
      const clonedElement = document.createElement('script');

      Array.from(scriptElement.attributes).forEach((attribute: Attr) => {
        clonedElement.setAttribute(attribute.name, attribute.value);
      });

      clonedElement.text = scriptElement.text;

      scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
    });
  }

  private closeOverlay(e?: Event): void {
    if (e) {
      e.stopPropagation();
    }
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.error('"Close" function is not set up');
    }
  }

  handleClick(e) {
    return e.target.classList.contains('iframe-overlay');
  }
}
