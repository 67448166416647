import {UrlUtils} from './api/urls';
import {CookiesManagement} from './cookies_management';
import {searchEventHandler, sphereEventHandler} from './custom_event_utils';
import {SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES as EVENTS} from './event-names';
import {MATOMO_EVENT_NAMES} from './metric-events';
import {Metrics} from './metrics';
import Router from './router';
import {
  ANIMATION_PATH_INDEX_KEY,
  ANIMATION_PATH_KEY,
  CLUSTER_CAPTION_REGEX,
  CLUSTER_NAME_REGEX,
  DIRECTION,
  PRODUCT_PAGE_ROUTES
} from './shared/constants';
import {
  isAnimateAction,
  isAudioAction,
  isContentOverlayAction,
  isInfoOverlayAction,
  isLinkAction,
  isProductOverlayAction,
  isSingleImageAction,
  isSocialLinkAction,
  isVideoOverlayAction,
  SphereItemType
} from 'shared/interfaces/planogram';
import {SphereApp} from './sphere_app';
import {SphereItem} from './sphere_item';
import {WebUtils} from './utils/web_utils';
import {AnimationPath, AnimationOptions, AnimationPathItem} from './interfaces/planogram.interface';
import {AnimateActionData} from 'shared/interfaces/planogram';
import {ActionType} from 'shared/interfaces/planogram';
import {Overlay} from './overlay';
import {L10nUtils} from './utils/l10n_utils';
import {AppUtils} from './utils/app_utils';
import CanvasRenderer from './canvas_renderer';
import {Planogram} from './planogram';
import {CameraControls} from './controls/camera_controls';
import {SphereItems} from './sphere_items';
import {ProductNavigationData} from './interfaces/product.interface';

const MIN_AUTOPLAY_DURATION = 500;
const AFTER_LOAD_ANIMATION_DELAY = 500;

export class InputHandler {
  isClusterSelected: boolean;
  private selectedCluster: string;

  private autoplayStarted: boolean = false;
  private autoplaySpeedTime: number;
  private autoplayTimeoutId: number;
  private repeatCallback: Function;
  private autoplayAnimation: any;
  private isAnimatingToProduct: boolean;

  constructor(
    private sphereApp: SphereApp,
    private canvasRenderer: CanvasRenderer,
    private overlay: Overlay,
    private planogram: Planogram,
    private cameraControls: CameraControls,
    private sphereItems: SphereItems
  ) {}

  setProductOverlay(productIdentifier: string, overlayType = PRODUCT_PAGE_ROUTES.GALLERY) {
    const item = this.sphereItems.findSphereItemByIdentifier(productIdentifier);
    if (item && !this.isAnimatingToProduct) {
      this.overlay.showItem(item, overlayType);
    }
  }

  navigateToAutoplayClusters() {
    if (this.autoplayStarted) {
      this.autoplaySpeedTime = Math.max(this.autoplaySpeedTime - 1000, MIN_AUTOPLAY_DURATION);
    } else if (this.selectedCluster) {
      this.startAutoplay();
    } else if (this.planogram.clustersOrder && this.planogram.clustersOrder.length) {
      const clusterId = AppUtils.extractClusterName(this.planogram.clustersOrder[0]);
      Router.navigateToCluster(this.planogram.name, clusterId, {autoplay: true});
    }
  }

  private autoplay() {
    this.repeatCallback = () =>
      (this.autoplayTimeoutId = window.setTimeout(this.autoplay.bind(this), this.autoplaySpeedTime));
    if (this.autoplayTimeoutId && this.autoplayStarted) {
      this.cycleThroughClusters(DIRECTION.RIGHT, true);
    } else {
      const clusterName = this.selectedCluster ? AppUtils.extractClusterName(`cluster-${this.selectedCluster}`) : '';
      Router.navigateToCluster(this.planogram.name, clusterName, {autoplay: true});
    }
  }

  handleClick(x: number, y: number) {
    let isCookieOpened = false;
    try {
      isCookieOpened = CookiesManagement.cookiePopup.isOpen();
    } catch (e) {
      console.error('--- Can not handle response from Cookies popup');
    }

    if (this.overlay.isShowing() || isCookieOpened) {
      return;
    }

    const {mesh, point} = this.canvasRenderer.getInteractableObjectAtScreenCoordinate(x, y);

    const item: SphereItem = mesh ? mesh.userData?.component : undefined;
    this.sphereApp.heatMapService.sendClickEvent(x, y, this.canvasRenderer.camera.currentZoomFraction(), item);

    sphereEventHandler.emit(EVENTS.CONTROL.CLICK_ITEM, {item});

    if (item === undefined) {
      return;
    }
    item.onClick(point);

    if (item.action) {
      this.handleAction(item);
    }
  }

  private handleAnimateAction(data: AnimateActionData, planogramName: string, activate: boolean, redirect = false) {
    switch (data.itemType) {
      case SphereItemType.Cluster:
        Router.navigateToCluster(planogramName, AppUtils.extractClusterName(data.clusterLink));
        break;
      case SphereItemType.Product:
        if (!redirect) {
          this.animateCameraToIdentifer(data.productIdentifier, activate);
        } else {
          if (data.productName) {
            Router.navigateToProductIdAndName(
              data.productIdentifier,
              data.productName,
              planogramName,
              !activate && PRODUCT_PAGE_ROUTES.SHOW
            );
          } else {
            Router.navigateToProductId(data.productIdentifier, planogramName, !activate && PRODUCT_PAGE_ROUTES.SHOW);
          }
        }
        break;
      case SphereItemType.AnimationPath:
        if (data.sphereName) {
          const [sphereName, language] = data.sphereName.split('_');
          Router.navigateToPlanogramWithPath(sphereName, data.itemId, {langCode: language});
        } else {
          Router.navigateToPlanogramWithPath(planogramName, data.itemId);
        }
        break;
      case SphereItemType.Image:
      case SphereItemType.Video:
      case SphereItemType.Text:
      case SphereItemType.TextArea:
      case SphereItemType.Shape:
      case SphereItemType.Curve:
        Router.navigateToItem(planogramName, data.itemId, data.itemType);
        break;
      default:
        Router.navigateToPlanogram(planogramName);
    }
  }

  handleAction(item: SphereItem) {
    if (item.action === undefined) return;
    if (isAnimateAction(item.action)) {
      const data = item.action.data;
      if (data.sphereName) {
        const language = data.sphereName.split('_').pop();
        data.sphereName = data.sphereName.replace(`_${language}`, '');
        L10nUtils.selectLanguage(language).then(() =>
          this.handleAnimateAction(data, data.sphereName, data.applyItemsActionInTheEnd, true)
        );
        CookiesManagement.init();
        this.overlay.hide();
        this.sphereApp.isSphereLoaded = false;
      } else {
        this.handleAnimateAction(data, this.planogram.name, data.applyItemsActionInTheEnd);
      }
      Metrics.storeTheEvent(
        this.planogram.name,
        'click',
        `${MATOMO_EVENT_NAMES.WEBGL_CLICK_ANIMATE}_${item.type.toLowerCase()}${WebUtils.getItemName(item, '-')}`
      );
      return;
    }

    if (isLinkAction(item.action) && item.action.type === ActionType.ExternalLink) {
      WebUtils.openLink(item.action.data.url);
      Metrics.storeTheEvent(
        this.planogram.name,
        'click',
        `${MATOMO_EVENT_NAMES.WEBGL_CLICK_LINK}-${item.action.data.url}${WebUtils.getItemName(item)}`
      );
      return;
    }

    if (isProductOverlayAction(item.action)) {
      const product = this.sphereItems.findSphereItemByIdentifier(item.action.data.productIdentifier);
      Router.navigateToProduct(product, PRODUCT_PAGE_ROUTES.GALLERY);
      return;
    }

    if (isAudioAction(item.action)) {
      const audioData = item.action.data;
      this.sphereApp.audio.playActionAudio(audioData.url);
      return;
    }

    if (isVideoOverlayAction(item.action)) {
      Router.navigateToVideoOverlay(item);
      return;
    }

    if (isLinkAction(item.action) && item.action.type === ActionType.IframeLink) {
      Router.navigateToIframe(item);
      return;
    }

    if (isSocialLinkAction(item.action)) {
      Router.navigateToSocialMedia(item);
      return;
    }

    if (isContentOverlayAction(item.action)) {
      Router.navigateToContentOverlay(item.action.data, item.planogram.name);
      return;
    }

    if (isInfoOverlayAction(item.action)) {
      Router.navigateToInfoOverlay(this.planogram.name, item.action.type, true);
      this.overlay.showItem(item);
      return;
    }

    if (isSingleImageAction(item.action)) {
      const imageData = item.data as any; // TODO: single image action type
      Router.navigateToImage(
        imageData.picture?.id || imageData.id,
        (imageData.picture?.name || imageData.image_name || '').split('.')[0].replace(/\W/g, ''),
        this.planogram.name,
        true
      );
      return;
    }

    this.overlay.showItem(item);
  }

  private animateCameraToCluster(clusterIdentification: string, upArrow: boolean = false, callback?: Function): void {
    if (!(this.planogram.clustersOrder && this.planogram.clustersOrder.length)) {
      return;
    }
    if (!callback && this.repeatCallback) {
      callback = this.repeatCallback;
    }
    if (!clusterIdentification) {
      this.selectedCluster = clusterIdentification = this.planogram.clustersOrder[0];
    }
    this.cameraControls.clearAnimation();
    let clusterName, animation;

    if (clusterIdentification.match(CLUSTER_CAPTION_REGEX)) {
      clusterName = AppUtils.extractClusterFullName(clusterIdentification);
    } else if (clusterIdentification.match(CLUSTER_NAME_REGEX)) {
      clusterName = AppUtils.extractClusterName(clusterIdentification);
    } else {
      clusterName = clusterIdentification;
    }

    const item = this.sphereItems.findClusterByClusterName(clusterName);
    const delay = this.sphereApp.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY;
    if (item) {
      if (this.selectedCluster === clusterIdentification && this.isClusterSelected && !upArrow) {
        animation = this.cameraControls.animateZoomFov();
        this.isClusterSelected = false;
      } else {
        animation = this.cameraControls.animateTo(item, callback, {clusterAnimation: true, delay});
        this.isClusterSelected = true;
      }
      this.selectedCluster = clusterIdentification;

      if (callback && animation) {
        this.autoplayAnimation = animation;
      } else {
        this.resetAutoplay();
      }
    } else {
      console.error('There are no cluster with this name');
    }
  }

  animateToClusterAfterLoad(clusterName: string) {
    searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);

    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
    };

    const isAutoplay = UrlUtils.getQueryValueFromUrl('autoplay') === 'true';
    if (isAutoplay && !this.autoplayStarted) {
      this.selectedCluster = clusterName;
      this.startAutoplay();
    }

    if (!isAutoplay) {
      this.resetAutoplay();
    }

    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateCameraToCluster(clusterName, true, afterAnimationEnd);
      if (isAutoplay && this.repeatCallback) {
        this.repeatCallback();
      }
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }

  private animateCameraToItem(
    identifier: string,
    callback: Function,
    options: AnimationOptions | AnimationPathItem
  ): void {
    const item = this.sphereItems.findSphereItemByIdentifier(identifier);
    if (item) {
      this.cameraControls.clearAnimation();
      this.cameraControls.animateTo(item, callback, options);
    } else {
      console.error('There is no item with this identifier');
    }
  }

  animationPath: AnimationPath;
  currentAnimationIndex: number | undefined;

  handleInitAnimationPath(pathIdentifier: string, arrowNavigation = false) {
    const animationPathItem = sessionStorage.getItem(ANIMATION_PATH_KEY);
    const animationPathIndex = sessionStorage.getItem(ANIMATION_PATH_INDEX_KEY);
    const animationPath =
      animationPathItem && !arrowNavigation
        ? JSON.parse(animationPathItem)
        : this.planogram.animation_paths.find(path => UrlUtils.slugify(path.name) === UrlUtils.slugify(pathIdentifier));

    if (!animationPath || (arrowNavigation && this.animationPath?.name === pathIdentifier)) {
      return;
    }

    this.animationPath = animationPath;
    sessionStorage.removeItem(ANIMATION_PATH_KEY);
    sessionStorage.removeItem(ANIMATION_PATH_INDEX_KEY);
    this.currentAnimationIndex = animationPathIndex !== null ? parseInt(animationPathIndex) - 1 : undefined;
    this.handleNextAnimation(false, arrowNavigation);

    return this.animationPath;
  }

  handleNextAnimation(reverse?: boolean, arrowsNavigation = false, withoutDelay?: boolean) {
    const {items: animations} = this.animationPath;
    if (this.currentAnimationIndex === undefined) {
      this.currentAnimationIndex = 0;
    } else {
      this.currentAnimationIndex += reverse ? -1 : 1;
      if (this.animationPath.loop) {
        this.currentAnimationIndex = (this.currentAnimationIndex + animations.length) % animations.length;
      } else {
        this.currentAnimationIndex = Math.max(0, Math.min(animations.length - 1, this.currentAnimationIndex));
      }
    }

    const animation = animations[this.currentAnimationIndex];
    if (!animation || !this.sphereApp.isSphereLoaded) {
      return;
    }

    const itemId = animation.itemId;
    const differentSphere =
      animation.planogramName !== this.planogram.name || animation.language !== L10nUtils.getCurrentLanguage();
    const item = differentSphere ? undefined : this.sphereItems.findSphereItemByIdentifier(itemId);

    if (differentSphere) {
      sessionStorage.setItem(ANIMATION_PATH_KEY, JSON.stringify(this.animationPath));
      sessionStorage.setItem(ANIMATION_PATH_INDEX_KEY, this.currentAnimationIndex.toString());
      Router.navigateToPlanogramWithPath(animation.planogramName, UrlUtils.slugify(this.animationPath.name), {
        langCode: animation.language
      });
    } else if (item === undefined) {
      this.handleNextAnimation(reverse);
    } else {
      const isFinalItem = this.currentAnimationIndex === animations.length - 1;
      this.animateCameraToItem(
        itemId,
        () => {
          if (!this.animationPath.autoplay) return;

          if (isFinalItem) {
            if (this.animationPath.applyFinalItemsAction && !arrowsNavigation) {
              this.handleAction(item);
            } else if (this.animationPath.loop) {
              if (!arrowsNavigation) {
                this.handleNextAnimation();
              }
            }
          } else {
            if (!arrowsNavigation) {
              this.handleNextAnimation();
            }
          }
        },
        {...animation, delay: arrowsNavigation || withoutDelay ? 0 : animation.delay}
      );
    }
  }

  redirectToProduct(action: string, productName: string = '') {
    const data = this.parseProductNavigationData(action);
    if (this.planogram.name === data.planogramName) {
      this.sphereApp.search.showSphereElements();
      this.overlay.hide();
      this.resetNavigation();
      const item = this.sphereItems.findSphereItemByIdentifier(data.productIdentifier);
      this.animateToProduct(item, () => {
        this.setProductOverlay(item.identifier, data.overlayView);
      });
      Router.navigateToProduct(item, data.overlayView);
    } else {
      const item = {
        planogram: {
          name: data.planogramName
        },
        name: productName,
        identifier: data.productIdentifier
      } as SphereItem;
      this.sphereApp.isSphereLoaded = false;
      Router.navigateToProduct(item, data.overlayView);
    }
  }

  private parseProductNavigationData(action: string): ProductNavigationData {
    const separator = '#';
    const parsedString = action.split(separator).slice(1);
    let overlayView: PRODUCT_PAGE_ROUTES | undefined;
    let planogramName = parsedString.shift();
    if (planogramName === PRODUCT_PAGE_ROUTES.SHOW) {
      overlayView = PRODUCT_PAGE_ROUTES.SHOW;
      planogramName = parsedString.shift();
    }
    const productIdentifier = parsedString.join('-');

    return {
      planogramName,
      productIdentifier,
      overlayView
    };
  }

  private startAutoplay() {
    this.autoplayStarted = true;
    this.autoplaySpeedTime =
      this.planogram.animationSettings.autoplay_delay + this.planogram.animationSettings.duration;
    this.autoplay();
  }

  resetNavigation() {
    if (!window.location.pathname.endsWith(this.planogram.name)) Router.navigateToPlanogram(this.planogram.name);
  }

  resetAutoplay() {
    window.clearTimeout(this.autoplayTimeoutId);
    this.autoplayStarted = false;
    this.repeatCallback = null;
    this.autoplayTimeoutId = null;
    this.cameraControls.removeAnimation(this.autoplayAnimation);
    Router.removeClusterAutoplayState();
  }

  private cycleThroughClusters(direction: DIRECTION, isAutoplay?: boolean): void {
    if (!(this.planogram.clustersOrder && this.planogram.clustersOrder.length)) {
      return;
    }
    const clusterOrderIndex = this.planogram.clustersOrder.indexOf(`cluster-${this.selectedCluster}`);
    const clustersMaxIndex = this.planogram.clustersOrder.length - 1;
    let nextIndex = 0;
    if (clusterOrderIndex >= 0) {
      nextIndex = clusterOrderIndex;
      if (direction === DIRECTION.RIGHT) {
        nextIndex = clusterOrderIndex + 1;
      } else if (direction === DIRECTION.LEFT) {
        nextIndex = clusterOrderIndex - 1;
      }
    } else if (direction === DIRECTION.LEFT) {
      nextIndex = clustersMaxIndex;
    }

    if (nextIndex > clustersMaxIndex) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = clustersMaxIndex;
    }
    const clusterIdentification = this.planogram.clustersOrder[nextIndex];
    Router.navigateToCluster(this.planogram.name, AppUtils.extractClusterName(clusterIdentification), {
      autoplay: isAutoplay
    });
  }

  animateCameraToClusterByArrows(direction: DIRECTION): void {
    switch (direction) {
      case DIRECTION.UP: {
        if (this.selectedCluster) {
          this.animateCameraToCluster(this.selectedCluster, true);
        } else if (this.planogram.clustersOrder?.length) {
          Router.navigateToCluster(this.planogram.name, AppUtils.extractClusterName(this.planogram.clustersOrder[0]));
        }
        break;
      }
      case DIRECTION.DOWN: {
        if (this.selectedCluster) {
          this.cameraControls.animateZoomFov();
        }
        break;
      }
      case DIRECTION.LEFT:
      case DIRECTION.RIGHT: {
        this.cycleThroughClusters(direction);
      }
    }
  }

  private animateCameraToIdentifer(identifier: string, openProductOverlay = true): void {
    const item = this.sphereItems.findSphereItemByIdentifier(identifier);
    if (item) {
      this.animateToProduct(item, () => {
        if (openProductOverlay) {
          this.overlay.showItem(item, PRODUCT_PAGE_ROUTES.GALLERY);
          Router.navigateToProduct(item);
        }
      });
    }
  }

  animateToProductAfterLoad(productIdentifier: string, overlayType?: PRODUCT_PAGE_ROUTES) {
    const item = this.sphereItems.findSphereItemByIdentifier(productIdentifier);
    const delay = this.sphereApp.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY;
    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
      this.overlay.showItem(item, overlayType);
    };
    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateToProduct(item, afterAnimationEnd, {duration: undefined, delay});
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }

  animateToItemAfterLoad(item: SphereItem, action?: boolean, overlay?: boolean) {
    const delay = this.sphereApp.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY;
    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
      if (action) {
        if (overlay) {
          this.overlay.showItem(item);
        } else {
          if (item.action?.type === ActionType.Animate) {
            this.autoplayTimeoutId = window.setTimeout(() => {
              this.handleAction(item);
            }, this.planogram.animationSettings.autoplay_delay);
          } else {
            this.handleAction(item);
          }
        }
      }
    };
    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateToProduct(item, afterAnimationEnd, {duration: undefined, delay});
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }

  animateToImageAfterLoad(itemIdentifier: string, action?: boolean) {
    const item = this.sphereItems.findSphereItemByIdentifier(itemIdentifier);
    const delay = this.sphereApp.isSphereLoaded ? 0 : AFTER_LOAD_ANIMATION_DELAY;
    const afterAnimationEnd = () => {
      CookiesManagement.isRedirectAnimationProcessing = false;
      CookiesManagement.init();
      if (action) {
        this.overlay.showImage(item);
      }
    };
    const fn = () => {
      CookiesManagement.isRedirectAnimationProcessing = true;
      this.animateToProduct(item, afterAnimationEnd, {duration: undefined, delay});
    };

    this.sphereApp.isSphereLoaded ? fn() : this.sphereApp.afterLoadQueue.push(fn);
  }

  private animateToProduct(item, afterAnimationEnd?: Function, options?: {duration: number; delay: number}) {
    if (item) {
      this.cameraControls.clearAnimation();
      this.autoplayAnimation = this.cameraControls.animateTo(
        item,
        () => {
          if (afterAnimationEnd) {
            afterAnimationEnd();
          }
        },
        options
      );
    }
  }

  isOverlayShowing() {
    return this.overlay.isShowing();
  }

  hideOverlay(withNavigation?: boolean) {
    this.overlay.hide(withNavigation);
  }
}
